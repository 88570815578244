export default function InputSelect({
  actionData, 
  name, 
  label, 
  selectData, 
  required, 
  className,
  onChangeRef
}) {
  const hasError = (actionData && actionData[name])
  return (
    <div className={`form-group ${className} ${hasError && 'error'}`}>
      <label htmlFor={name}>{label}{required && <span></span>}</label>
      <select name={name} defaultValue="null" onChange={onChangeRef}>
        <option value="null" disabled>-- Select a State --</option>
        {
          selectData.map((opt, idx) => {
            return (
              <option key={idx} value={opt.abbr}>{opt.display}</option>
            )
          })
        }
      </select>
      { hasError && <span className="error-msg">{actionData[name]}</span>}
    </div>
  )
}