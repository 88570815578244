export const FormDataSchema = [{
  type: "hidden",
  name: "dateSubmitted",
  defaultValue: ""
}, {
  type: "form-security",
  name: "secureFullName",
  defaultValue: ""
}, {
  type: "text",
  label: "Name of Organization",
  name: "orgName",
  required: true,
  defaultValue: ""
}, {
  type: "textfield",
  label: "Brief Summary of the Organization's Mission",
  name: "orgSummary",
  required: true,
  defaultValue: ""
}, {
  type: "text",
  label: "EIN",
  name: "orgEIN",
  required: true,
  defaultValue: ""
}, {
  type: "text",
  label: "Street Address",
  name: "orgAddressStreet",
  required: true,
  defaultValue: ""
}, {
  type: "text",
  label: "Street Address Line 2",
  name: "orgAddressStreet2",
  required: false,
  defaultValue: ""
}, {
  type: "text",
  label: "City",
  name: "orgAddressCity",
  required: true,
  defaultValue: ""
}, {
  type: "select",
  label: "State",
  name: "orgAddressState",
  required: true,
  defaultValue: ""
}, {
  type: "number",
  label: "Postal Code",
  name: "orgAddressPostal",
  required: true,
  defaultValue: ""
}, {
  type: "url",
  label: "Website",
  name: "orgWebsite",
  required: false,
  defaultValue: ""
}, {
  type: "number",
  label: "Annual Budget (without the grant)",
  name: "orgAnnualBudget",
  required: true,
  defaultValue: ""
}, {
  type: "text",
  label: "Contact at Organization",
  name: "orgContactName",
  required: true,
  defaultValue: ""
}, {
  type: "tel",
  label: "Contact Phone Number",
  name: "orgContactPhone",
  required: true,
  defaultValue: ""
}, {
  type: "email",
  label: "Contact Email",
  name: "orgContactEmail",
  required: true,
  defaultValue: ""
}, {
  type: "number",
  label: "Amount requested",
  name: "grantAmount",
  required: true,
  defaultValue: ""
}, {
  type: "textarea",
  label: "Purpose or Designation of Funds",
  name: "grantPurpose",
  required: true,
  defaultValue: ""
}, {
  type: "text",
  label: "Referred By",
  name: "grantConnection",
  required: true,
  defaultValue: ""
}, {
  type: "file",
  label: "Proof of 501&copy;(3) status",
  name: "proof501",
  defaultValue: ""
}]