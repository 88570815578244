export const StatesData = [
  {
    display: 'Alabama',
    abbr: 'AL'
  },
  {
    display: 'Alaska',
    abbr: 'AK'
  },
  {
    display: 'Arizona',
    abbr: 'AZ'
  },
  {
    display: 'Arkansas',
    abbr: 'AR'
  },
  {
    display: 'California',
    abbr: 'CA'
  },
  {
    display: 'Colorado',
    abbr: 'CO'
  },
  {
    display: 'Connecticut',
    abbr: 'CT'
  },
  {
    display: 'Delaware',
    abbr: 'DE'
  },
  {
    display: 'District Of Columbia',
    abbr: 'DC'
  },
  {
    display: 'Florida',
    abbr: 'FL'
  },
  {
    display: 'Georgia',
    abbr: 'GA'
  },
  {
    display: 'Hawaii',
    abbr: 'HI'
  },
  {
    display: 'Idaho',
    abbr: 'ID'
  },
  {
    display: 'Illinois',
    abbr: 'IL'
  },
  {
    display: 'Indiana',
    abbr: 'IN'
  },
  {
    display: 'Iowa',
    abbr: 'IA'
  },
  {
    display: 'Kansas',
    abbr: 'KS'
  },
  {
    display: 'Kentucky',
    abbr: 'KY'
  },
  {
    display: 'Louisiana',
    abbr: 'LA'
  },
  {
    display: 'Maine',
    abbr: 'ME'
  },
  {
    display: 'Maryland',
    abbr: 'MD'
  },
  {
    display: 'Massachusetts',
    abbr: 'MA'
  },
  {
    display: 'Michigan',
    abbr: 'MI'
  },
  {
    display: 'Minnesota',
    abbr: 'MN'
  },
  {
    display: 'Mississippi',
    abbr: 'MS'
  },
  {
    display: 'Missouri',
    abbr: 'MO'
  },
  {
    display: 'Montana',
    abbr: 'MT'
  },
  {
    display: 'Nebraska',
    abbr: 'NE'
  },
  {
    display: 'Nevada',
    abbr: 'NV'
  },
  {
    display: 'New Hampshire',
    abbr: 'NH'
  },
  {
    display: 'New Jersey',
    abbr: 'NJ'
  },
  {
    display: 'New Mexico',
    abbr: 'NM'
  },
  {
    display: 'New York',
    abbr: 'NY'
  },
  {
    display: 'North Carolina',
    abbr: 'NC'
  },
  {
    display: 'North Dakota',
    abbr: 'ND'
  },
  {
    display: 'Ohio',
    abbr: 'OH'
  },
  {
    display: 'Oklahoma',
    abbr: 'OK'
  },
  {
    display: 'Oregon',
    abbr: 'OR'
  },
  {
    display: 'Pennsylvania',
    abbr: 'PA'
  },
  {
    display: 'Rhode Island',
    abbr: 'RI'
  },
  {
    display: 'South Carolina',
    abbr: 'SC'
  },
  {
    display: 'South Dakota',
    abbr: 'SD'
  },
  {
    display: 'Tennessee',
    abbr: 'TN'
  },
  {
    display: 'Texas',
    abbr: 'TX'
  },
  {
    display: 'Utah',
    abbr: 'UT'
  },
  {
    display: 'Vermont',
    abbr: 'VT'
  },
  {
    display: 'Virginia',
    abbr: 'VA'
  },
  {
    display: 'Washington',
    abbr: 'WA'
  },
  {
    display: 'West Virginia',
    abbr: 'WV'
  },
  {
    display: 'Wisconsin',
    abbr: 'WI'
  },
  {
    display: 'Wyoming',
    abbr: 'WY'
  }
];

