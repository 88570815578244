import FormBasic from './components/FormBasic'

import logo from './assets/prf_logo_color.svg'
import './app.css'

export default function App() {
  return (
    <div className="app-container bg-base flex column align-center full-height full-width">
      <header>
        <h1>
          <img className="logo" src={logo} alt="Pine Rock Foundation" />
        </h1>
      </header>
      <main className="flex column align-center">
        <section className="description">
          <p className="dark">
          All applications are due by July 15th, 2024. Funds will be distributed by September. The deadline for 2025 is June 15th with distributions by September<sup>&dagger;</sup>.
          </p>
          <p className="instructions dark">
           All fields are required.
          </p>
          <p className="warning-msg">No unsolicited requests.</p>
        </section>

        <section>
          <FormBasic />
        </section>
      </main>

      <footer>
        <div className="flex column">
          <span className="disclaimer">
            <sup>&dagger;</sup> Dates subject to change
          </span>
          <span className="copyright">
            &copy; 2024 Pine Rock Foundation, Inc
          </span>
        </div>
      </footer>
    </div>
  )
}
