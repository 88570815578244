export default function InputTextfield({
  actionData, 
  name, 
  label, 
  type, 
  required,
  className,
  onChangeRef,
  maxLength
}) {
  const hasError = (actionData && actionData[name])

  return (
    <div className={`form-group ${className} ${hasError && 'error'}`}>
      <label htmlFor={name}>{label} <span className="small">{maxLength} character max</span></label>
      <textarea type={type} name={name} onChange={onChangeRef} maxLength={maxLength} />
      {
        hasError && <span className="error-msg">{actionData[name]}</span>
      }
    </div>
  )
}