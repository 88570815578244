import { useState } from 'react'
import axios from 'axios'
import xssFilters from 'xss-filters'

import { FormDataSchema } from '../data/schemaFormData'
import { StatesData } from '../data/schemaStatesData'

import InputBasic from './InputBasic'
import InputFormSecurity from './InputFormSecurity'
import InputHidden from './InputHidden'
import InputSelect from './InputSelect'
import InputTextfield from './InputTextfield'
import { enforceEmail } from '../utils'

export default function FormBasic() {
  const [formSubmitMessage, setFormSubmitMessage] = useState(null)
  const [formData, setFormData] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [files, setFiles] = useState(null)
  const [submitStatus, setSubmitStatus] = useState('pre')
  // const [uploadedFile, setUploadedFile] = useState(null)

  const onChangeFileHandle = (evt) => {
    if (evt.target.files) {
      setFiles(evt.target.files)
    }
  }

  const onChangeHandle = (evt) => {
    evt.preventDefault()
    setFormData({
      ...formData,
      [evt.target.name]: xssFilters.inHTMLData(evt.target.value)
    })
  }

  const onSubmitHandle = (evt) => {
    evt.preventDefault()
    let tmpErrors = formErrors

    // Loop through formSchema and check for errors
    FormDataSchema.forEach(({
      label,
      name,
      required
    }) => {
      if (
        required && 
        (!formData.hasOwnProperty(name) || formData[name] === '')
      ) {
        tmpErrors[name] = `${label} cannot be left blank.`
      }

      if (
        name === 'orgContactEmail' && 
        (formData[name] !== '' && !enforceEmail(formData[name]))
      ) {
          tmpErrors[name] = `Please enter a valid email.`
      }
    })

    // Make sure there is a file
    if (!files) {
      tmpErrors['proof501'] = `Please upload proof of 501 status.`
    }
    tmpErrors = {}

    if (Object.values(tmpErrors).length > 0) {
      setFormErrors(null)
      setTimeout(() => {
        setSubmitStatus('submit_complete')
        setFormErrors(tmpErrors)
      }, 300)
    } else {
      uploadFileAndSendEmail(files)
    }
  }

  const uploadFileAndSendEmail = (files) => {
    const formattedFormData = new FormData()
    Object.entries(formData).forEach(([name, value]) => {
      formattedFormData.append(name, value)
    })
    if (files) {
      Array.from(files).forEach(file => formattedFormData.append('filesToUpload[]', file))
      formattedFormData.append('submitted', true)
    }

    if (formattedFormData.hasOwnProperty('secureFullName') && formattedFormData.secureFullName !== '') {
      setFormSubmitMessage(`Thank you, Mr. Robot. Goodbye.`)
      return
    }

    axios.post('https://pinerockfoundation.org/combined-service/', formattedFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(response => {
      setSubmitStatus('submit_complete')
      setTimeout(() => setFormSubmitMessage(response.data.message), 1000)
    })
    .catch(err => {
      console.log(`err`, err)
    })
  }

  if (formSubmitMessage) {
    return (
      <div className="form-submit-message">
        <div className="msg">
          {formSubmitMessage}
        </div>
        <div className="return">
          <button className="btn pill bg-secondary base" onClick={() => setFormSubmitMessage(null)}>Back</button>
        </div>
      </div>
    )
  }

  return (
    <div className="form-basic">
      <form
        method="post"
        encType="multipart/form-data"
        onSubmit={onSubmitHandle}
      >
        <InputHidden
          actionData={formErrors}
          name="dateSubmitted"
          type="hidden"
          value={new Date().toISOString()}
          onChangeRef={onChangeHandle}
          required={false}
        />
        <InputFormSecurity
          actionData={formErrors}
          name="secureFullName"
          onChangeRef={onChangeHandle}
        />
        <div className="form-section">
          <h2>Nonprofit Information</h2>
          <InputBasic
            actionData={formErrors}
            type="text"
            name="orgName"
            label="Name of Organization"
            required={false}
            onChangeRef={onChangeHandle}
          />
          <InputTextfield
            actionData={formErrors}
            type="text"
            name="orgSummary"
            label="Brief Summary of the Organization's Mission"
            required={false}
            onChangeRef={onChangeHandle}
            maxLength={500}
          />
          <InputBasic
            actionData={formErrors}
            type="text"
            name="orgEIN"
            label="EIN"
            required={false}
            className="width-3-4"
            onChangeRef={onChangeHandle}
          />
          <InputBasic
            actionData={formErrors}
            type="text"
            name="orgWebsite"
            label="Website"
            required={false}
            className="width-3-4"
            onChangeRef={onChangeHandle}
          />
          <InputBasic
            actionData={formErrors}
            type="text"
            name="orgAnnualBudget"
            label="Annual Budget (without the grant)"
            required={false}
            className="width-2-4 input-icon money"
            onChangeRef={onChangeHandle}
          />

          <h3>Mailing Address</h3>
          <div className="grid grid-4-columns">
            <InputBasic
              actionData={formErrors}
              type="text"
              name="orgAddressStreet"
              label="Street Address"
              required={false}
              className="item-a"
              onChangeRef={onChangeHandle}
            />
            <InputBasic
              actionData={formErrors}
              type="text"
              name="orgAddressStreet2"
              label="Street Address Line 2"
              required={false}
              className="item-b"
              onChangeRef={onChangeHandle}
            />
            <InputBasic
              actionData={formErrors}
              type="text"
              name="orgAddressCity"
              label="City"
              required={false}
              className="item-c"
              onChangeRef={onChangeHandle}
            />
            <InputSelect
              actionData={formErrors}
              type="select"
              name="orgAddressState"
              label="State"
              selectData={StatesData}
              className="item-d"
              required={false}
              onChangeRef={onChangeHandle}
            />
            <InputBasic
              actionData={formErrors}
              type="text"
              name="orgAddressPostal"
              label="Postal Code"
              required={false}
              className="item-e"
              onChangeRef={onChangeHandle}
            />
          </div>

          <h3>Contact at Organization</h3>
          <div className="grid grid-4-columns">
            <InputBasic
              actionData={formErrors}
              type="text"
              name="orgContactName"
              label="Contact Name"
              required={false}
              className="col-2 row-1"
              onChangeRef={onChangeHandle}
            />
            <InputBasic
              actionData={formErrors}
              type="tel"
              name="orgContactPhone"
              label="Contact Phone"
              required={false}
              className="col-2 start-3 row-1"
              onChangeRef={onChangeHandle}
            />
            <InputBasic
              actionData={formErrors}
              type="email"
              name="orgContactEmail"
              label="Contact Email"
              required={false}
              className="col-4 row-2 input-icon email"
              onChangeRef={onChangeHandle}
            />
          </div>
        </div>
        <div className="form-section">
          <h2>Grant Request</h2>
          <div className="grid grid-4-columns">
            <InputBasic
              actionData={formErrors}
              type="text"
              name="grantAmount"
              label="Amount Requested"
              required={false}
              className="col-2 row-1 input-icon money"
              onChangeRef={onChangeHandle}
            />
            <InputBasic
              actionData={formErrors}
              type="text"
              name="grantConnection"
              label="Referred By"
              required={false}
              className="col-2 start-3 row-1"
              onChangeRef={onChangeHandle}
            />
            <InputTextfield
              actionData={formErrors}
              type="textarea"
              name="grantPurpose"
              label="Purpose or Designation of Funds"
              required={false}
              className="col-4 row-2"
              onChangeRef={onChangeHandle}
              maxLength={400}
            />
          </div>

          <label htmlFor="proof501">Proof of 501&copy;(3) status</label>
          <input type="file" name="proof501" onChange={onChangeFileHandle} multiple="multiple" />
          {
            formErrors?.proof501 && <span className="error-msg">{formErrors.proof501}</span>
          }
        </div>

        <div className="form-section padding-extra-44">
         
          <div className={`submit-btn-container ${submitStatus}`}>
            <input
              className={`btn pill bg-secondary base`}
              type="submit"
              name="submit"
              value="Submit"
            />
            <div className="loader" />
          </div>
        </div>
      </form>
    </div>
  )
}
