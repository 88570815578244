export default function InputFormSecurity({
  name, 
  type,
  onChangeRef
}) {
  return (
    <div className={`form-security`}>
      <input type={type} name={name} onChange={onChangeRef} />
    </div>
  )
}